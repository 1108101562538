.h-modal div div {
    background: #000;
}

thead tr {
    color: #000;
}

tbody tr td:first-child {
    color: #fff;
}