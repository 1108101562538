@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: ChekraRegular;
  src: url('./fonts/Chakra_Petch/ChakraPetch-SemiBold.ttf');
}

body {
  margin: 0;
  font-family: ChekraRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blue-glassmorphism {
  background: rgb(39, 51, 89, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.wallet-adapter-button-trigger {
  background-color: #9D7E49 !important;
}

@media(max-width: 640px) {
  .wallet-adapter-button-trigger {
    font-size: 14px !important;
    line-height: normal !important
      /* background-color: #9D7E49 !important; */
  }
}

.mapboxgl-marker {
  background-image: url('./img/hasuki1.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.custom-marker {
  background-image: url('./img/userpin.png') !important;
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-marker svg {
  display: none;
}

.mapboxgl-popup-content {
  position: relative;
  background: rgba(20, 20, 20, 0.833) !important;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 10px 10px 15px;
  pointer-events: auto;
  color: #fff;
  overflow: auto;
  width: fit-content
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-tip {
  box-shadow: 0 8px 20px rgb(0 0 0 / 60%);
  border-bottom-color: rgba(20, 20, 20, 0.957) !important;
  background: none;
  border-top-color: rgba(20, 20, 20, 0.9) !important;
  ;
}

.popup-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  overflow: auto;
}


/* alert Section 
 */

.alertSectionCont {
  position: relative;
  z-index: 999;
  /* left: 50%; */
  top: 2%;
  /* transform: translateX(-10px); */
  width: 100%;
  transition: all ease-in 0.3s;
}

.alertSection {
  position: absolute;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px 20px;
  text-align: center;
  border-radius: 10px;
  top: 3%;
}

.alertSection img {
  width: 40px;
}

label {
  color: #fff !important;
}

.headersBtn {
  color: #ECD0A0;
  text-shadow:
    -1px -1px 0 #000,
    /* top-left */
    1px -1px 0 #000,
    /* top-right */
    -1px 1px 0 #000,
    /* bottom-left */
    1px 1px 0 #000;
  /* bottom-right */
  font-weight: bold;

}